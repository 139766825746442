<!--
	A single file component.
	The first section is called the Template, and is basically HTML.
	You'll notice brackets around certain code, these are Tags which allow data from the script to be injected into the template.
	Changing these in the script will automatically update the HTML.
	For example, when the order of the pages is changed, the order in the HTML is updated to reflect it.
-->
<div class="carousel">
	<img class="squiggle" src="{ plugin_path + '/images/squiggle.png' }" alt="">
	<button class="left" on:click="rotate(-1)">
		<img src="{ plugin_path + '/images/arrow_left.png' }" alt="rotate-left" />
	</button>
	<div class="hidden-wrapper">
		<!--loop through each page and add each to the carousel-->
		{#each pages as page (page.id)}
		<div class="carousel-page" animate:flip>
			<!--the page for the carousel the `animate:flip` directive applys the `flip()` animation (described below) when the pages are reordered-->
			<a href="{page.pageURL}">
				<img src="{ page.imageSource }" alt="{ page.pageName }" class="carousel-image">
			</a>
		</div>
		{/each}
	</div>
	<button class="right" on:click="rotate(1)">
		<img src="{ plugin_path + '/images/arrow_right.png' }" alt="rotate-right" />
	</button>
</div>

<style>
	/*
			standard css, with one caveat: it is Scoped, and will only apply to the elements in the template above.
			*/
	.carousel {
	  width: 100%;
	  position: relative;
	  max-width: 1330px;
	  margin: 0 auto;
	}
	.hidden-wrapper {
	  display: flex;
	  flex-direction: row;
	  width: 166%;
	  position: relative;
	  left: -33%;
	}

	button {
	  background: transparent;
	  border: none;
	  padding: 0;
	  position: absolute;
	  bottom: 0;
	  margin: 0;
	  z-index: 1000;
	  cursor: pointer;
	}
	.left {
	  left: 13.5%;
	}
	.right {
	  right: 13.5%;
	}
	button img {
	  max-width: 8vw;
	}

	.carousel-page {
	  position: relative;
	  overflow: hidden;
	  transform: scale(0.6);
	  width: 33%;
	  transition: 900ms opacity ease-in-out;
	  margin: 8px;
	  transform-origin: center center;
	}
	.carousel-page * {
	  user-select: none;
	}
	.carousel-page:nth-child(1) {
	  opacity: 0;
	}
	.carousel-page:nth-child(2) {
	  transform: translateX(20%) scale(0.6);
	}
	.carousel-page:nth-child(3) {
	  transform: scale(1);
	  z-index: 2;
	}
	.carousel-page:nth-child(4) {
	  transform: translateX(-20%) scale(0.6);
	}
	.carousel-page:nth-child(5) {
	  opacity: 0;
	}
	.carousel-page:nth-child(n + 6) {
	  visibility: collapse;
	}
	.carousel-page a {
	  display: flex; /* bug workaround */
	}
	.carousel-image {
	  width: calc(
	    33vw
	  ); /* Fix the width of the image as percentage of viewport width */
	  height: calc(33vw * 0.5625); /* Fix the height to a ratio of the width */
	  object-fit: cover; /* scale and crop the image instead of stretching */
	}
	.carousel-page:hover {
	  transition: 240ms box-shadow ease-in-out;
	  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.35);
	}

	.squiggle {
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	  width: 81%;
	}
</style>

<script>
	import * as eases from "eases-jsnext";

	export default {
	  methods: {
	    rotate(direction) {
	      // rotate the pages: if direction === -1, rotate left. If direction === 1, rotate right
	      const { pages } = this.get(); // get the array of pages
	      const newPages = [...pages]; // clone them

	      // rotate them in the clone
	      if (direction === -1) {
	        newPages.push(newPages.shift());
	      } else if (direction === 1) {
	        newPages.unshift(newPages.pop());
	      }

	      // update the original pages array, triggering reorder
	      this.set({ pages: newPages });
	    }
	  },
	  animations: {
	    // code for the animation
	    flip(node, animation, params) {
	      const direction = animation.to.x < animation.from.x ? -1 : 1; // determine the direction

	      // figure out where the page is located in the array
	      var pageLocation = 0;
	      while ((node = node.previousSibling) != null) pageLocation++;

	      // Calculate the maximum separation between the start and end of the animation.
	      // Depending on where the element is, we may need to worry about either the left- or right-hand side of the animation.
	      let dx = 0;
	      if (pageLocation < 2) {
	        dx = animation.from.right - animation.to.right;
	      } else if (pageLocation > 2) {
	        dx = animation.from.left - animation.to.left;
	      } else {
	        dx = animation.from.left - animation.to.left;
	      }

	      return {
	        duration: 700, // duration of the animation
	        easing: eases.sineOut, // animation easing
	        css: (t, u) => {
	          // offset is used to handle smooth animations between the full-size, centered middle page, and the scaled pages beside it.
	          let offset = "";
	          if (pageLocation === 1) {
	            if (direction === -1) {
	              offset = " + " + (1 - u) * 20 + "%";
	            } else {
	              offset = " + 20%";
	            }
	          } else if (pageLocation === 3) {
	            if (direction === 1) {
	              offset = " - " + (1 - u) * 20 + "%";
	            } else {
	              offset = " - 20%";
	            }
	          } else if (pageLocation === 2) {
	            if (direction === -1) {
	              offset = " - " + u * 20 + "%";
	            } else {
	              offset = " - " + u * 20 + "%";
	            }
	          }

	          // describe the final animation for each element
	          if (pageLocation === 2) {
	            return `transform: translateX(calc(${u *
	              dx}px${offset})) scale(${(1 - u) * 0.4 + 0.6})`;
	          } else if (
	            (pageLocation === 1 && direction === -1) ||
	            (pageLocation === 3 && direction === 1)
	          ) {
	            return `transform: translateX(calc(${u *
	              dx}px${offset})) scale(${u * 0.4 + 0.6})`;
	          } else {
	            return `transform: translateX(calc(${u *
	              dx}px${offset})) scale(0.6)`;
	          }
	        }
	      };
	    }
	  }
	};
</script>