// import the App component from src/App.html
import App from "./App.html";

// set the path for asset files (like images).
// In testing, it is empty, but when used in Wordpress, we need to get the path to the plugin.
let plugin_path = "";
if (window.carousel_path !== undefined) {
  plugin_path = carousel_path;
}

// a sample page description. Used for testing.
let testPage = {
  pageName: "test",
  pageURL: "#",
  imageSource: plugin_path + "/images/featured_image.png"
};

// Similar to the plugin_path above, we need to get the pages from the plugin, or use the testPage if it's not there.
let pages = [];
if (window.carousel_pages !== undefined) {
  pages = carousel_pages.map((page, index) => {
    return { ...page, id: index };
  });
} else {
  pages = [
    { ...testPage, id: "1" },
    { ...testPage, id: "2" },
    { ...testPage, id: "3" },
    { ...testPage, id: "4" },
    {
      ...testPage,
      id: "5",
      imageSource:
        "https://www.galan-montgomery.com/img/there-turn.771a127d.png"
    },
    {
      ...testPage,
      id: "6",
      imageSource:
        "https://www.galan-montgomery.com/img/getloaded-mobile.b0d0678f.png"
    }
  ];
}

// attach the App component to the page.
const app = new App({
  target: document.querySelector("#triple-carousel"), // We are going to inject our app into the element with id="triple-carousel"
  data: {
    pages: pages, // pass the pages array to the App Component
    plugin_path: plugin_path // and the plugin_path
  }
});

export default app; // allow this to be imported by other components
